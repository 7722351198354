<template>
  <LayoutStickyHeaderComponent
    :class="$style.root"
    :data-sticky="sticky ? '' : null"
  >
    <div :class="$style.items">
      <button
        @click="$emit('onToggleMenuOpen')"
        :class="$style.button"
      >
        <LayoutTextWithBarComponent>Menu</LayoutTextWithBarComponent>
      </button>

      <div id="teleport-breadcrumb" :class="$style.breadcrumbs" />
    </div>

    <div :class="$style.items">
      <div id="teleport-link-to-close" :class="$style.linkToClose" />
      <NavigationLanguageSwitcherComponent :sticky="sticky" />
    </div>
  </LayoutStickyHeaderComponent>
</template>

<script setup>
const props = defineProps({
  menuOpen: {
    type: Boolean,
    default: false,
  },
});

const { y } = useWindowScroll()
const lastY = ref(0)
const dir = ref(null)

watchDebounced(y, (newValue, oldValue) => {
  lastY.value = newValue
  dir.value = newValue > oldValue ? 'down' : 'up'
}, { debounce: 100, maxWait: 500 })

const sticky = computed(() => {
  return dir.value === 'up' || y.value === 0 || props.menuOpen
})
</script>

<style module lang=scss>
.root {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: var(--unit--spacer);
}

.items {
  display: flex;
  gap: var(--unit--horizontal);
}

.breadcrumbs {
  display: flex;
  gap: var(--unit--horizontal);
  color: var(--color--primary);
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.linkToClose {
  color: var(--color--primary);
}

.button {
  composes: reset-button from global;
}

.button:hover {
  color: var(--color--pink);
}
</style>
