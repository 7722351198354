<template>
  <transition name="slide-in">
    <div
      :class="$style.root"
      v-if="!isOpen"
      @click="isOpen = !isOpen"
      ref="root"
      id="fence"
    >
      <client-only>
        <div
          :class="$style.closed"
          :style="{
            top: posTop
          }"
          v-html="$t('openingTimes.closed')"
        />
      </client-only>

      <LayoutStickyLogoComponent />
    </div>
  </transition>
</template>

<script setup>
// 1
const root = ref(null)

const mainStore = useMainStore()
const openingTimesData = await mainStore?.fetchOpeningTimesData()

const isOpen = ref(openingTimesData?.isOpen)

watch(isOpen, (value) => {
  if (!value) {
    document.documentElement.toggleAttribute('data-overflow-hidden', true)
  } else {
    document.documentElement.removeAttribute('data-overflow-hidden')
  }
})

onMounted(() => {
  setTimeout(() => {
    if (!openingTimesData?.isOpen) {
      document.documentElement.toggleAttribute('data-overflow-hidden', true)
    }
  }, 50)

  setTimeout(() => {
    isOpen.value = true
  }, 3000)
})

// 2
const { height: windowHeight, width: windowWidth } = useWindowSize()

const convertRemToPixels = (rem) => {
  const actualRem = rem?.includes('calc(') ? rem?.replace('calc(', '')?.replace(')', '')?.split("*")[0] : rem
  const calcPercent = rem?.includes('calc(') ? parseFloat(rem?.replace('calc(', '')?.replace(')', '')?.split("*")[1]) : 1
  return (parseFloat(actualRem) * calcPercent) * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const calcTop = () => {
  if (process.client) {
    const lineHeight = convertRemToPixels(getComputedStyle(document.documentElement).getPropertyValue('--font-default--line-height'))
    const spacer = convertRemToPixels(getComputedStyle(document.documentElement).getPropertyValue('--unit--spacer'))
    const unit = (lineHeight * 2 + spacer)
    const steps = Math.floor((windowHeight.value / 2) / unit)
    return `${unit * steps + (lineHeight  / 2)}px`
  }
}

const posTop = ref(calcTop())

watch([windowHeight, windowWidth], () => {
  posTop.value = calcTop()
})
</script>

<style module>
.root {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  height: var(--100dvh);

  z-index: var(--z-index--fence);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  --test: var(--font-default--line-height);
  --size: calc(var(--font-default--line-height) * 2);
  background: repeating-linear-gradient(
    to bottom,
    var(--color--black),
    var(--color--black) var(--size),
    var(--color--white) var(--size),
    var(--color--white) calc(var(--size) + var(--unit--spacer))
  );

  --color--primary: var(--color--gray);
}

.closed {
  color: var(--color--white);
  text-align: center;
  text-transform: uppercase;

  position: absolute;
  left: 0;
  right: 0;
}
</style>
