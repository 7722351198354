import qs from 'qs'

export const queryFooter = () => {
  const query = qs.stringify(
    {
      populate: {
        informations: {
          fields: [
            'i18n_richtext_en',
            'i18n_richtext_nl'
          ]
        },
        links: {
          populate: {
            ...queryFragmentLinks,
            titles: true
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `footer?${query}`
}
