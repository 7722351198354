<template>
  <a
    :href="data?.external_url"
    target="_blank"
    :class="$style.root"
  >
    {{ title?.trim() }} ↗
  </a>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object
  }
})

const currentLanguageCode = computed(() => baseI18nGetActiveCode())
const title = computed(() => {
  return navigationGetTitle(props?.data, currentLanguageCode.value)
})
</script>

<style module lang="scss">
.root {
  composes: reset-link from global;
}
</style>
