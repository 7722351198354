<template>
  <div :class="$style.root">
    <SvgoLogo
      :class="$style.logo"
    />

    <div :class="$style.address">
      Stadhouderskade 86<br />
      1073 AT Amsterdam
    </div>
  </div>
</template>

<style module lang=scss>
.root {
  padding: var(--unit--vertical) var(--unit--horizontal);

  position: sticky;
  bottom: 0;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: var(--unit--spacer);

  pointer-events: none;
}

.logo {
  max-width: 18rem;
  width: 100%;
  fill: var(--color--primary);
  overflow: visible;

  @media (max-width: $breakpoint-m) {
    max-width: 11.5rem;
  }
}

.address {
  color: var(--color--primary);

  @media (max-width: $breakpoint-s) {
    display: none;
  }
}
</style>
