export const queryFragmentLinks = {
  about_page: {
    populate: {
      titles: true
    }
  },
  home_page: {
    populate: {
      titles: true
    }
  },
  page_entry: {
    publicationState: 'live',
    populate: {
      titles: true,
      slugs: true
    }
  },
  participant_entry: {
    publicationState: 'live',
    fields: ['slug', 'title'],
    populate: {
      titles: true,
      slugs: true
    }
  },
  participants_page: {
    populate: {
      titles: true
    }
  },
  permanent_work_entry: {
    publicationState: 'live',
    populate: {
      titles: true,
      slugs: true
    }
  },
  permanent_works_page: {
    populate: {
      titles: true
    }
  },
  program_entry: {
    publicationState: 'live',
    populate: {
      titles: true,
      slugs: true
    }
  },
  programs_page: {
    populate: {
      titles: true
    }
  },
  publication_entry: {
    publicationState: 'live',
    populate: {
      titles: true,
      slugs: true
    }
  },
  publications_page: {
    populate: {
      titles: true
    }
  }
}
