<template>
  <div :class="$style.root">
    <!-- <div :class="$style.note">
      {{ $t('footer.newsletter.note') }}
    </div> -->

    <div :class="$style.inputContainer">
      <input
        :placeholder="$t('footer.newsletter.email')"
        :class="$style.input"
        v-model="email"
      >
      <button
        :class="$style.button"
        @click="subscribe"
        :disabled="!isValid ? '' : null"
      >
        <SvgoArrow />
      </button>
    </div>

    <LayoutHorizontalLineComponent :class="$style.border" />

    <div :class="$style.options">
      <div>
        {{ $t('footer.newsletter.disclaimer') }}
      </div>
      <template
        v-for="(addience, index) in addiences"
        :key="index"
      >
        <div
          :class="$style.option"
        >
          <input
            type="checkbox"
            v-model="addiences[index].selected"
            :id="`addience-${index}`"
            :class="$style.checkbox"
          />
          <div :class="$style.checkmark" />
          <label
            :class="$style.label"
            :for="`addience-${index}`"
          >
            {{ addience?.label }}
          </label>
        </div>
      </template>
    </div>


    <ul
      v-if="res"
      :class="$style.confirmation"
    >
      <li v-for="(i, index) in res" :key="index">
        {{ $t(`footer.newsletter.confirmation.${i?.status}`, { label: labelForAudienceId(i?.audienceId) }) }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import isEmail from 'validator/lib/isEmail'

// 1
const { data, error } = await useAsyncData(() =>
  $fetch('/api/strapi-rest', { query: { path: queryFooter() } })
)

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(data))
})

const information = computed(() => {
  return localizedData?.value?.informations?.current_locale || localizedData?.value?.informations?.i18n_richtext_en
})

const links = computed(() => {
  return localizedData?.value?.links?.filter((i) => {
    if (i?.__component === 'shared.link') {
      return navigationLinkItem(i)?.routeReference?.name
    } else {
      return true
    }
  })
})

// 2
const res = ref('')
const email = ref('')
const addiences = reactive([
  {
    label: 'Woonhuis',
    id: '84c5a5e7f2',
    selected: true
  },
  {
    label: 'De Ateliers',
    id: '21311ba7da',
    selected: false
  }
])

const isValid = computed(() => {
  return isEmail(email.value) && addiences?.some((i) => i.selected)
})

const subscribe = async () => {
  if (!isValid.value) return

  const audienceIds = addiences?.filter((i) => i.selected).map((i) => i.id)
  const { data } = await useFetch('/api/mailchimp', { params: { email: email.value, audienceIds: audienceIds } })
  res.value = data?.value
}

const labelForAudienceId = (id) => {
  return addiences?.find((i) => i.id === id)?.label
}
</script>

<style module lang=scss>
.root {
}

.inputContainer {
  display: flex;
  align-items: baseline;
}

.inputContainer svg {
  width: 1.5ch;
  height: 1ch;
  overflow: visible;
  stroke-width: var(--stroke--default);
  stroke: currentColor;
  transform: scaleX(-1);
  display: block;
  stroke-linecap: round;
}

.input {
  composes: reset-input from global;
  width: 100%;
  line-height: 1;
  margin-bottom: calc(var(--unit--spacer) / 4 * 2);
}

.input::placeholder {
  color: var(--color--primary);
}

.button {
  composes: reset-button from global;
}

.button[disabled] {
  cursor: not-allowed;
}

.button[disabled] * {
  stroke: var(--color--gray);
}

.options {
  composes: font-size-small from global;
  padding-top: var(--unit--spacer);
  text-align: center;
}

.option {
  display: inline-grid;
  align-items: baseline;
  grid-template-columns: auto 1fr;
  gap: .5ch;

  margin-right: var(--unit--spacer);

  user-select: none;
  cursor: pointer;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  width: calc(var(--fs, 1em) * .725);
  height: calc(var(--fs, 1em) * .725);
  background-color: var(--color--gray);
}

.checkbox:checked ~ .checkmark {
  background-color: var(--color--black);
}

.checkbox:checked ~ .label {
  text-decoration-line: underline;
}

.label {
  display: inline-block;
  cursor: pointer;
}

.label:hover {
  text-decoration-line: underline;
}

.confirmation {
  composes: reset-list font-size-small from global;
  margin-top: var(--unit--spacer);
}
</style>
