<template>
  <ul
    v-if="links?.length > 0"
    :class="$style.root"
  >
    <li
      v-for="link in links"
      :key="link.id"
    >
      <I18nLinkWithFallback
        :name="linkObject(link)?.routeReference?.name"
        :localizedData="linkObject(link)"
        :class="$style.link"
        v-if="link?.__component === 'shared.link'"
      >
        {{ linkTitle(link) || $t('shared.more') }}
      </I18nLinkWithFallback>
      <a
        :href="link?.url"
        :class="$style.link"
        target="_blank"
        v-else-if="link?.__component === 'shared.url-with-label'"
      >
        {{ link?.label || $t('shared.more') }}
      </a>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  links: {
    type: Array
  },
})

const linkObject = (link) => {
  return navigationLinkItem(link)
}

const linkTitle = (link) => {
  return (linkObject(link)?.titles?.current_locale?.trim() || linkObject(link)?.titles?.i18n_string_en?.trim()) || linkObject(link)?.title
}
</script>

<style module>
.root {
  composes: reset-list from global;

  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--horizontal);
}

.link {
  composes: reset-link from global;
}

.link:hover {
  text-decoration-line: underline;
}
</style>
