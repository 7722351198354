<template>
  <div :class="$style.root">
    <NavigationSiteHeaderComponent
      :menuOpen="menuOpen"
      @onToggleMenuOpen="menuOpen = !menuOpen"
    />

    <transition name="slide-in">
      <NavigationMenuComponent
        v-show="menuOpen"
        :isOpen="menuOpen"
        @onCloseMenu="menuOpen = false"
      />
    </transition>

    <FenceComponent />

    <main :class="$style.main">
      <slot />
    </main>

    <FooterComponent />
    <LayoutStickyLogoComponent />

  </div>
</template>

<script setup>
const menuOpen = ref(false)
</script>

<style module>
.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>
