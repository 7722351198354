<template>
  <footer :class="$style.root">
    <FooterNewsletterComponent :class="$style.newsletter" />

    <div :class="$style.borderTop">
      <LayoutHorizontalLineComponent :class="$style.border" />
      <LayoutHorizontalLineComponent :class="$style.border" />
      <LayoutHorizontalLineComponent :class="$style.border" />
      <LayoutHorizontalLineComponent :class="$style.border" />
      <LayoutHorizontalLineComponent :class="$style.border" />
      <LayoutHorizontalLineComponent :class="$style.border" />
    </div>

    <div :class="$style.column">
      <CoreTextComponent v-if="information" :string="information" :class="$style.information" />
      <FooterLinksComponent v-if="links?.length > 0" :links="links" />
    </div>

    <SharedOpeningTimesComponent
      :isOpen="openingTimesData?.isOpen"
      :openingTimes="openingTimesData?.data"
      fontSizeOption="default"
    />
  </footer>
</template>

<script setup>
// 1
const { data, error } = await useAsyncData(() =>
  $fetch('/api/strapi-rest', { query: { path: queryFooter() } })
)

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(data))
})

const information = computed(() => {
  return localizedData?.value?.informations?.current_locale || localizedData?.value?.informations?.i18n_richtext_en
})

const links = computed(() => {
  return localizedData?.value?.links?.filter((i) => {
    if (i?.__component === 'shared.link') {
      return navigationLinkItem(i)?.routeReference?.name
    } else {
      return true
    }
  })
})

// 2
const mainStore = useMainStore()
const openingTimesData = await mainStore?.fetchOpeningTimesData()
</script>

<style module lang=scss>
.root {
  margin: var(--unit--spacer) var(--unit--horizontal--double);
  margin-top: calc(var(--unit--vertical--sextuple) + var(--unit--vertical--tripple));

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--unit--horizontal--tripple);
  grid-row-gap: var(--unit--vertical);

  align-items: start;

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: var(--unit--vertical--double);
  }
}

.newsletter {
  composes: container--default from global;

  grid-column: 1 / -1;

  margin-bottom: var(--unit--vertical--sextuple);
}

.borderTop {
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: calc(var(--unit--horizontal--tripple) + var(--unit--spacer) * 2);

  @media (max-width: $breakpoint-m) {
    grid-template-columns: repeat(3, 1fr);
    & div:nth-child(-n+3) {
      display: none;
    }
  }

  @media (max-width: $breakpoint-s) {
    grid-template-columns: repeat(2, 1fr);
    & div:nth-child(-n+4) {
      display: none;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.information {
  max-width: var(--container--default--without-padding);
  width: 100%;
}
</style>
