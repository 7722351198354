<template>
  <menu
    :class="$style.root"
    ref="root"
    tabindex="0"
    @keyup.esc="$emit('onCloseMenu')"
  >
    <ul :class="$style.list">
      <li
        v-for="(item, index) in items"
        :key="`item-${index}`"
        :class="$style.item"
      >
        <NavigationMenuItemComponent
          :data="item"
        />
      </li>
    </ul>
  </menu>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const root = ref(null)
const emit = defineEmits(['onCloseMenu'])

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

// get items
const { data, error } = await useAsyncData(() =>
  $fetch('/api/strapi-rest', { query: { path: queryMenusMenu() } })
)

const page = computed(() => baseStrapiGetAttributes(data))
const items = computed(() => {
  return navigationGetMenuItems(page.value?.items?.data)
})

// close self
onClickOutside(root, () => {
  emit('onCloseMenu')
}, { ignore: ['button', 'a'] })

nuxtApp.hook('page:start', () => { emit('onCloseMenu') })

const open = computed(() => props.isOpen)
watch(open, (value) => {
  document.documentElement.toggleAttribute('data-overflow-hidden', value)
})
</script>

<style module lang="scss">
.root {
  position: fixed;
  z-index: var(--z-index--menu);

  top: 0;
  left: 0;

  padding: 0;
  margin: 0;

  background-color: var(--color--white);
}

.list {
  composes: reset-list from global;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: var(--unit--vertical) var(--unit--horizontal);
  padding-top: calc(var(--unit--vertical) * 1);

  margin: 0;
}

.button {
  composes: reset-button from global;
}

.button:hover {
  color: var(--color--pink);
}
</style>
