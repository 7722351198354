<template>
  <I18nLink
    :to="routeObject"
    :class="$style.root"
    :data-active-parent="activeParent ? '' : null"
  >
    <LayoutTextWithBarComponent>{{ title }}</LayoutTextWithBarComponent>
  </I18nLink>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const route = useRoute()

const props = defineProps({
  data: {
    type: Object
  }
})

const item = computed(() => {
  return navigationLinkItem(props?.data)
})

const title = computed(() => {
  return i18nGetLocalizedData(item?.value?.titles)?.current_locale || i18nGetLocalizedData(item?.value?.titles)?.i18n_string_en
})

const slug = computed(() => {
  return i18nGetLocalizedData(item?.value?.slugs)?.current_locale // || i18nGetLocalizedData(item?.value?.slugs)?.i18n_slug_en
})

const routeObject = computed(() => {
  return baseRouteGetObject(item?.value?.routeReference?.name, slug.value)
})

const routeBaseName = computed(() => nuxtApp.$getRouteBaseName())

const activeParent = computed(() => {
  const name = item?.value?.routeReference?.name
  const routesWithChildren = ['fellows', 'permanent-works', 'program', 'publications']
  const routeWithChildren = routesWithChildren?.some(i => i?.startsWith(name))
  if (routeWithChildren) {
    const match = routesWithChildren?.find(i => i?.startsWith(name))
    return routeBaseName.value?.startsWith(match)
  }
})
</script>

<style module lang="scss">
.root {
  composes: reset-link from global;
}

.root:hover,
.root[data-active-parent],
.root:global(.router-link-exact-active) {
  color: var(--color--pink);
}
</style>
