export const navigationGetMenuItems = (items: array) => {
  if (!items) { return }
  if (items instanceof Array === false) { return }items

  return items.map((item) => {
    if (isValidUrl(item?.attributes?.url) && item?.attributes?.title) {
      return {
        type: 'externalLink',
        level: 1,
        ...item?.attributes
      }
    } else if (navigationLinkItem(item?.attributes)) {
      return {
        type: 'internalLink',
        level: 1,
        ...item?.attributes
      }
    } else {
      return null
    }
  })?.filter((item) => item !== null)
}

const isValidUrl = urlString => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}
